import React from 'react'
import LibraryWrapper from './LibraryWrapper'
import { ReportQuiz } from '../../components/library'
import theme from '../../theme'

const profile = {
    role: 'Child',
    name: 'James',
    avatar: 'panda',
    id: 'b7dd4698-d0a5-11eb-b8bc-0242ac130003'
}


function section() {
    return (
        <LibraryWrapper>
            <ReportQuiz title="Medication" questions={[
                {
                    title: 'Did you use your rescue inhaler?',
                    answer: 'Yes',
                    notes: [
                        '1 dose used',
                        'Last dose at 3:30 PM'
                    ]
                },
                {
                    title: 'Did you take your daily medication?',
                    answer: 'Yes',
                    notes: [
                        'Taken in PM'
                    ]
                },
            ]} />
        </LibraryWrapper>
    )
}
export default section
